.homeIcon {
    position: fixed;
    top: 10px;
    left: 20px;
    color: #000;
    font-size: 40px;
    cursor: pointer;
    z-index: 9998;
    transition: color 0.3s;
}

.homeIcon:hover {
    color: #2980b9;
}